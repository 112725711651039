<template>
  <document-view-form :injectFormData="injectFormData" v-on="$listeners" />
</template>

<script>
import DocumentViewForm from "@/views/modules/document/viewForm/viewForm.vue";

export default {
  components: { DocumentViewForm },
  computed: {
    injectFormData() {
      return { meetingId: this.$route.params.meetingId };
    },
  },
};
</script>
